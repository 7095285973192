import { makeStyles } from "tss-react/mui";
import { Props } from "./index";

export const useStyles = makeStyles<Props<any, any, any, any>>()((theme) => {
  return {
    inputRoot: {
      paddingRight: "0px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    endAdornment: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    popupIndicator: {
      marginRight: "8px",
      borderRadius: "4px",
      width: "24px",
      height: "24px",
      "&:hover": {
        backgroundColor: "#9EAEB94D",
        transition: "none",
      },
    },
    clearIndicator: {
      borderRadius: "4px",
      marginRight: "4px",
      "&:hover": {
        transition: "none",
        backgroundColor: "#9EAEB94D",
      },
    },
    root: {
      color: theme.palette.text.primary,
      width: "100%",
      "& .MuiFormControl-root": {
        display: "flex",
        justifyContent: "center",
      },
      "& label": {
        color: theme.palette.text.primary,
      },
      "& label.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
    input: {
      minHeight: theme.spacing(5.5),
      border: `1px solid ${theme.colors.neutral5}`,
      borderRadius: theme.spacing(1 / 2),
      fontSize: 14,
      backgroundColor: theme.colors.neutral0,

      "& .MuiAutocomplete-inputRoot": {
        minHeight: theme.spacing(4.5),
        paddingBottom: 0,
        fontSize: 14,

        "&::after, &::before": {
          display: "none",
        },
      },
    },
    label: {
      marginBottom: theme.spacing(2),
      fontSize: 14,
      "&$label": {
        color: theme.colors.neutral6,
      },
      position: "relative",
      transform: "translate(0, 8px)",
      "&.Mui-focused": {
        transform: "translate(0, 8px)",
        color: theme.colors.ocean5,
      },
      "&.Mui-error": {
        "&.Mui-focused": {
          color: theme.colors.fire5,
        },
      },
      "&.Mui-disabled, &.Mui-error": {
        color: theme.colors.neutral6,
      },
      "& .MuiInputLabel-asterisk": {
        display: "none",
      },
    },

    searchIcon: {
      marginLeft: theme.spacing(1),
      transform: "none",
    },
    paper: {
      borderRadius: "4px",
      boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.25), 0px 6px 8px rgba(0, 0, 0, 0.13)",
      position: "relative",
      top: "4px",
    },
    option: {
      "&:hover": { backgroundColor: theme.colors.ocean1 },
    },
    focused: {
      borderColor: theme.colors.ocean4,
      "&:hover": {
        borderColor: theme.colors.ocean4,
      },
    },
  };
});
