import usersApiV2Client from "api/clients/usersApiV2Client";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useAccessControl } from "providers/accessControlProvider";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";
import { UsersApiModels } from "api/clients/usersApiV2Client";
import { Props } from "./index";
import { useSwaggerSwr } from "api/clients/genericApiClient";
import gosApiV1Client from "api/clients/gosApiV1Client";
import debounce from "lodash.debounce";
import usersApiV1Client from "api/clients/usersApiV1Client";
import buildODataQuery from "api/clients/buildODataQuery";
import { UserEntity } from "api/swagger/users.api.1.0";

type FormValues = UsersApiModels.CreateUserRequestWeb;

export const useAddNewUserModalForm = (props: Props) => {
  const { onSubmit: _onSubmit, onClose: _onClose } = props;
  const { data: companies } = useSwaggerSwr(gosApiV1Client.CompaniesApi.getApiCompanies);
  const [submissionError, setSubmissionError] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const { currentCompanyId } = useCurrentCompanyId();
  const { hasScaleManagementPrivileges } = useAccessControl();
  const defaultValues: Partial<UsersApiModels.CreateUserRequestWeb> = {
    companyId: hasScaleManagementPrivileges == false ? currentCompanyId : null,
    language: "en-US",
  };

  const methods = useForm<FormValues>({
    defaultValues,
    mode: "all",
  });

  const [showPassword, setShowPassword] = useState(false);

  const onClose = useCallback(() => {
    setSubmissionError(null);
    setIsSubmitting(false);
    methods.reset(defaultValues);
    _onClose();
  }, [_onClose, methods]);

  const onSubmit = useCallback(
    async (data: FormValues) => {
      setIsSubmitting(true);
      setSubmissionError(null);

      const createUserRequestWeb: UsersApiModels.CreateUserRequestWeb = {
        ...data,
        forceChangePasswordAtNextSignIn: false,
      };

      try {
        const res = await usersApiV2Client.UsersApi.postV2Users({
          createUserRequestWeb,
        });
        setIsSubmitting(false);
        _onSubmit?.();
        onClose();
        router.push({
          pathname: "/users/[userId]",
          query: { userId: res.data.id },
        });
      } catch (error) {
        setIsSubmitting(false);
        setSubmissionError(error);
      }
    },
    [_onSubmit, onClose, router],
  );

  const emailAvailableCallback = useCallback(
    debounce(async (email: string) => {
      try {
        const users = await usersApiV1Client.UsersApi.getV1Users(
          buildODataQuery<UserEntity>({
            select: ["id"],
            filter: {
              mail: email,
            },
          }),
        );

        // user can't be found by email,
        // therefore it "should" be possible to attempt creation
        if (!users.data.value.length) {
          return null;
        }

        methods.setError("email", {
          message: t("users:userEmailValidation.emailAlreadyUsed"),
        });

        return t("users:userEmailValidation.emailAlreadyUsed");
      } catch (error) {
        methods.setError("email", {
          message: t("users:userEmailValidation.emailExistenceCantBeVerified"),
        });

        return t("users:userEmailValidation.emailExistenceCantBeVerified");
      }
    }, 500),
    [methods],
  );

  return {
    methods,
    onSubmit,
    onClose,
    companies,
    submissionError,
    isSubmitting,
    hasScaleManagementPrivileges,
    showPassword,
    setShowPassword,
    emailAvailableCallback,
  };
};
