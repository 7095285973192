import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Icon, Tooltip } from "@scaleaq/scaleui";
import clsx from "clsx";
import React from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";
import { useStyles } from "./styles";

export type Props<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "options" | "getOptionLabel" | "fieldError" | "renderInput" | "value"
> & {
  otherClasses?: Record<string, string>;
  options: T[];
  labelKey: string;
  setActiveOption: (val: T) => void;
  label?: string;
  getOptionLabel?: (opt: T) => string;
  getSelected?: (opt: T, val: T) => boolean;
  error?: FieldError;
  endAdornement?: string | JSX.Element;
  name?: string;
  value?: T;
  autoFocus?: boolean;
};

export const SearchBar = <
  T = any,
  Multiple extends boolean = true,
  DisableClearable extends boolean = true,
  FreeSolo extends boolean = true,
>(
  props: Props<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const {
    name,
    id,
    label,
    otherClasses,
    options = [],
    labelKey,
    onChange: onChangeProp,
    setActiveOption,
    getOptionLabel,
    getSelected,
    endAdornement,
    error,
    autoFocus,
    onInputChange,
    filterOptions,
    ...rest
  } = props;
  const { classes } = useStyles(props);
  const handleOnChange = (e: React.SyntheticEvent, val: T) => {
    setActiveOption && setActiveOption(val);
    onChangeProp && onChangeProp(e, val as any, null, null);
  };

  return (
    <Autocomplete
      {...(rest as AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>)}
      id={id || name}
      fullWidth
      options={options}
      getOptionLabel={(option) => {
        return getOptionLabel ? getOptionLabel(option as T) : ((option as any)[labelKey] as string) ?? "";
      }}
      isOptionEqualToValue={getSelected}
      onChange={(e, val) => handleOnChange(e, val as any)}
      className={classes.root}
      classes={{
        ...otherClasses,
        paper: classes.paper,
        option: classes.option,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
        inputRoot: classes.inputRoot,
      }}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={option[id]}>
            {option[labelKey]}
          </li>
        );
      }}
      popupIcon={<StyledChevronDown />}
      clearIcon={<Icon.Small.Close />}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      renderInput={(params) => {
        const isFocused = document.activeElement?.id === (id || name);
        return (
          <FormControl fullWidth focused={isFocused}>
            {label && (
              <InputLabel shrink={false} disableAnimation className={classes.label} htmlFor={name}>
                <span>{label}</span>
              </InputLabel>
            )}
            <Tooltip tooltip={error?.message}>
              <TextField
                {...params}
                className={clsx(classes.input, [props.className], {
                  [classes.focused]: isFocused,
                })}
                id={name}
                variant="standard"
                name={name}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" className={classes.searchIcon}>
                      <Icon.Large.Search />
                    </InputAdornment>
                  ),
                  endAdornment: endAdornement ?? params.InputProps.endAdornment,
                }}
                autoFocus={autoFocus}
              />
            </Tooltip>
          </FormControl>
        );
      }}
    />
  );
};
const StyledChevronDown = styled(Icon.Small.ChevronDown)`
  display: flex;
  align-items: center;
`;
