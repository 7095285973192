// some general OData query resolvers for individual filters

export enum ConditionOperator {
  OR = "OR",
  AND = "AND",
}

export const or = (...stringChunks: string[]) =>
  stringChunks?.filter((x) => x).join(` ${ConditionOperator.OR} `);
export const and = (...stringChunks: string[]) =>
  stringChunks?.filter((x) => x).join(` ${ConditionOperator.AND} `);
export const nested = (conditions: string) => `(${conditions})`;

export enum LogicalOperator {
  /** Equals */
  EQ = "eq",
  /** Not Equals */
  NE = "ne",
  /** Greater Than */
  GT = "gt",
  /** Greater Than or Equal */
  GE = "ge",
  /** Less Than */
  LT = "lt",
  /** Less Than or Equal */
  LE = "le",
  /** Not */
  NOT = "not",
}

export const addSingleQuotesIfString = (value: string | number | boolean) =>
  typeof value == "string" ? `'${value}'` : value;
export const _in = (property: string, value: (string | number | boolean)[]) =>
  `${property} in [${value?.map((x) => (typeof x == "string" ? `'${x}'` : x)).join(", ")}]`;
const comparison =
  (operator: LogicalOperator) =>
  (property: string, value: string | number | boolean, autoQuote = true) =>
    `${property} ${operator} ${autoQuote ? addSingleQuotesIfString(value) : value}`;
export const eq = comparison(LogicalOperator.EQ);
export const ne = comparison(LogicalOperator.NE);
export const gt = comparison(LogicalOperator.GT);
export const ge = comparison(LogicalOperator.GE);
export const lt = comparison(LogicalOperator.LT);
export const le = comparison(LogicalOperator.LE);
export const not = comparison(LogicalOperator.NOT);
const oneArgFunction = (functionName: string) => (property: string) => `${functionName}(${property})`;
const twoArgsFunction = (functionName: string) => (property0: string, property1: string) =>
  `${functionName}(${property0}, '${property1}')`;

export const contains = twoArgsFunction("contains");
export const endsWith = twoArgsFunction("endsWith");
export const indexOf = twoArgsFunction("indexOf");
export const length = oneArgFunction("length");
export const startsWith = twoArgsFunction("startsWith");
export const matchesPattern = twoArgsFunction("matchesPattern");
export const tolower = oneArgFunction("tolower");
export const toupper = oneArgFunction("toupper");
export const trim = oneArgFunction("trim");

/**
 * @see: http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part2-url-conventions.html#sec_StringandCollectionFunctions
 */
export const StringFunctions = {
  contains,
  endsWith,
  indexOf,
  length,
  startsWith,
  matchesPattern,
  tolower,
  toupper,
  trim,
};

export const ceiling = oneArgFunction("ceiling");
export const floor = oneArgFunction("floor");
export const round = oneArgFunction("round");

/**
 * @see: http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part2-url-conventions.html#sec_ArithmeticFunctions
 */
export const ArithmeticFunctions = {
  ceiling,
  floor,
  round,
};

export function itemInSelectedValues<T>(value: (string | number)[], field: keyof T): string {
  return _in(String(field), value ?? []);
}

/** A resolver to filter by IN given multiple fields in the same state. e.g. CompanyId, SiteId, GroupId. */
export function fieldInSelectedValues<T extends Record<string, any[]>>(
  value: string[],
  getField: (value: string) => [field: keyof T, value?: string],
  conditionOperator = ConditionOperator.AND,
  initialQueryState: T = {} as T,
): string {
  const queryChunks: Record<string, string[]> = value?.reduce((accumulator, currentValue) => {
    const [field, value] = getField(currentValue);
    return {
      ...accumulator,
      [field]: [...(accumulator[field] ?? []), value ?? currentValue],
    };
  }, initialQueryState);

  const stringChunks = Object.entries(queryChunks).map(([key, values]) => itemInSelectedValues(values, key));

  return stringChunks?.length ? nested(stringChunks?.join(` ${conditionOperator} `)) : null;
}

export type TypedObjectEntries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];
