import { TFunction } from "i18n";
export const noWhitespaceRule = (t: TFunction, fieldName: string) => {
  return {
    value: /^(?!\s*$)/,
    message: t("common:fieldCannotOnlyBeWhitespace", { field: fieldName }),
  };
};

export const noSpecialCharactersRule = (v: string, fieldName: string, t: TFunction) => {
  const regEx = /^[0-9a-zA-Z]+$/;
  if (v.match(regEx)) {
    return null;
  }

  return t("common:fieldCannotContainSpecialCharacters", { field: fieldName });
};

export const maxLengthRule = (maxLength: number, t: TFunction, fieldName: string) => {
  return {
    value: maxLength,
    message: t("common:fieldMaxLength", {
      field: fieldName,
      maxLength: maxLength,
    }),
  };
};

export const maxRule = (max: number, t: TFunction, fieldName: string) => {
  return {
    value: max,
    message: t("common:fieldMax", {
      field: fieldName,
      max: max?.toLocaleString("nb-NO"),
    }),
  };
};

export const numberRule = (value: string, fieldName: string, t: TFunction) => {
  if (isNaN(Number(value)) || value === "") {
    return t("common:fieldNumber", { field: fieldName });
  }
  return null;
};

export const integerRule = (v: string, fieldName: string, t: TFunction) => {
  if (!v || v === "") return null;
  if (!Number.isInteger(+v)) {
    return t("common:fieldInteger", { field: fieldName });
  }
  return null;
};

export const isSafePassword = (v: string, t: TFunction) => {
  if (!v.match(/.{8,16}/)) {
    return t("users:passwordValidation.shouldContainNumberOfCharacters");
  }

  if (!v.match(/[0-9]+/)) {
    return t("users:passwordValidation.shouldContainNumber");
  }

  if (!v.match(/[A-Z]+/)) {
    return t("users:passwordValidation.shouldContainUppercaseCharacter");
  }

  if (!v.match(/[a-z]+/)) {
    return t("users:passwordValidation.shouldContainLowercaseCharacter");
  }

  if (!v.match(/[@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>]/)) {
    return t("users:passwordValidation.shouldContainSymbol");
  }

  if (!v.match(/^\S*$/)) {
    return t("users:passwordValidation.shouldNotContainSpaces");
  }

  const invalidCharacters = [...v.matchAll(/[^{A-Z}{a-z}{0-9}{"@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>"}]+/g)];

  if (invalidCharacters.length) {
    return t("users:passwordValidation.shouldContainOnlyAllowedCharacters", {
      characters: invalidCharacters.join(", "),
    });
  }

  return null;
};

export const versionRule = (v: string, fieldName: string, t: TFunction) => {
  if (!/^\d+\.\d+\.\d+$/.test(v)) {
    return t("common:fieldVersion", { field: fieldName });
  }
  return null;
};

export const maxMBRule = (max: number, v: string, fieldName: string, t: TFunction) => {
  if (v.length > max * 1024 * 1024) {
    return t("common:fieldMaxMB", {
      field: fieldName,
      maxMB: max,
    });
  }
  return null;
};

export const codeRule = (v: string, fieldName: string, t: TFunction) => {
  if (!v) {
    return null;
  }
  if (!/^[A-Za-z0-9-_]+$/.test(v)) {
    return t("common:fieldCode", { field: fieldName });
  }
  return null;
};
