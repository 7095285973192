import {
  Column,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Row,
} from "@scaleaq/scaleui";
import ReactHookFormSearchBar from "components/formComponents/ReactHookFormSearchBar";
import { ReactHookFormTextField } from "components/formComponents/ReactHookFormTextField";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalProps } from "types/common";
import { validateEmail } from "validation/commonValidationMethods";
import { isSafePassword } from "validation/validationRules";
import { useStyles } from "./styles";
import { useAddNewUserModalForm } from "./useAddNewUserModalForm";

export type Props = ModalProps & {
  onSubmit?: () => void;
};

export const AddNewUserModalForm = (props: Props) => {
  const {
    methods,
    onSubmit,
    onClose,
    companies,
    submissionError,
    isSubmitting,
    hasScaleManagementPrivileges,
    emailAvailableCallback,
    showPassword,
    setShowPassword,
  } = useAddNewUserModalForm(props);

  const { handleSubmit } = methods;

  const { control } = methods;
  const { classes } = useStyles(props);
  const { t } = useTranslation();

  return (
    <Modal open={props.open} setOpen={onClose} width="512px" data-test="addNewUserModal">
      <ModalHeader title={t("users:newUser")} onClose={onClose} />
      <ModalContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
            <Column alignItems="flex-start" gap="2.4rem">
              <ReactHookFormTextField
                control={control}
                placeholder="user@example.com"
                name="email"
                label={t("users:username")}
                rules={{
                  required: t("common:fieldRequired", { field: t("users:username") }),
                  validate: {
                    email: (v: string) => {
                      return validateEmail(v, t);
                    },
                    emailAvailable: emailAvailableCallback,
                  },
                }}
                autoFocus={true}
              />

              <Row alignItems="flex-end">
                <ReactHookFormTextField
                  control={control}
                  name="password"
                  label={t("users:password")}
                  type={showPassword ? "text" : "password"}
                  rules={{
                    required: t("common:fieldRequired", { field: t("users:password") }),
                    validate: {
                      safePassword: (v: string) => {
                        return isSafePassword(v, t);
                      },
                    },
                  }}
                />
                <IconButton
                  aria-label={t("common:toggleShown")}
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                  Icon={showPassword ? <Icon.Large.EyeClosed /> : <Icon.Large.EyeOpen />}
                  type="button"
                />
              </Row>

              <ReactHookFormTextField
                control={control}
                name="displayName"
                label={t("users:displayName")}
                rules={{
                  required: t("common:fieldRequired", { field: t("common:name") }),
                }}
              />

              <ReactHookFormTextField control={control} name="givenName" label={t("users:firstName")} />

              <ReactHookFormTextField control={control} name="surname" label={t("users:lastName")} />

              {hasScaleManagementPrivileges && (
                <>
                  <ReactHookFormSearchBar
                    name={"companyId"}
                    control={control}
                    otherClasses={{ root: classes.searchBar }}
                    disablePortal={true}
                    getSelected={(opt, val) => {
                      return opt.companyId === val?.companyId;
                    }}
                    options={companies}
                    labelKey={"companyName"}
                    valueKey={"companyId"}
                    label={t("common:company")}
                    key={companies?.length}
                    rules={{
                      required: t("common:fieldRequired", { field: t("common:company") }),
                    }}
                  />
                </>
              )}
            </Column>
          </form>
        </FormProvider>
      </ModalContent>
      <ModalFooter
        actionButtons={[
          {
            label: t("common:save"),
            onClick: handleSubmit(async (data) => await onSubmit(data)),
            loading: isSubmitting,
            "data-testid": "add-new-user-modal-save-button",
          },
          {
            label: t("common:cancel"),
            onClick: onClose,
            disabled: isSubmitting,
            "data-testid": "add-new-user-modal-cancel-button",
          },
        ]}
        error={submissionError && t("common:errors.generalError")}
      />
    </Modal>
  );
};
