import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import React from "react";
import { SearchBar } from "../SearchBar";
import { ErrorWrapper } from "@scaleaq/scaleui";
import styled from "styled-components";

export type Props<ItemType, FormType = any> = {
  control?: Control<FormType>;
  name: FieldPath<FormType>;
  id?: string;
  defaultValue?: string | number;
  formControlOptions?: FormControlProps;
  otherClasses?: Record<string, string>;
  options?: ItemType[];
  labelKey: string;
  valueKey?: string;
  setActiveOption?: (val: ItemType) => void;
  label?: string;
  getOptionLabel?: (opt: ItemType) => string;
  getSelected?: (opt: ItemType, val: ItemType) => boolean;
  value?: ItemType;
  endAdornement?: string;
  rules?: UseControllerProps["rules"];
  autoFocus?: boolean;
  disablePortal?: boolean;
  className?: string;
};

const ErrorWrapperContainer = styled.div`
  position: relative;
  bottom: -4px;
  z-index: 10;
`;

const ReactHookFormSearchBar = <ItemType, FormType>(props: Props<ItemType, FormType>) => {
  const {
    name,
    id,
    label,
    otherClasses,
    options = [],
    defaultValue,
    labelKey,
    valueKey,
    setActiveOption,
    getOptionLabel,
    getSelected,
    value: propValue,
    endAdornement,
    control,
    rules,
    autoFocus,
    disablePortal,
    className,
  } = props;
  const { setValue } = useFormContext();

  return (
    <FormControl>
      <Controller
        control={control as Control<FieldValues>}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { name, ...fieldRest }, fieldState: { error } }) => (
          <div>
            <SearchBar
              {...fieldRest}
              otherClasses={otherClasses}
              value={propValue}
              id={id}
              name={name}
              getSelected={getSelected}
              onChange={(_, val) =>
                setValue(name, (val as unknown as Record<string, any>)?.[valueKey ?? labelKey] ?? "")
              }
              options={options}
              labelKey={labelKey}
              setActiveOption={setActiveOption}
              label={label}
              endAdornement={endAdornement}
              getOptionLabel={getOptionLabel}
              autoFocus={autoFocus}
              disablePortal={disablePortal}
              className={className}
            />
            {error && (
              <ErrorWrapperContainer>
                <ErrorWrapper error={error.message}>
                  <></>
                </ErrorWrapper>
              </ErrorWrapperContainer>
            )}
          </div>
        )}
      />
    </FormControl>
  );
};
export default ReactHookFormSearchBar;
